<!--
  -- Created by zed on 2021/11/15
  -- Copyright © 2017 www.moemone.com. All rights reserved.
  --
  -- 扫码下载页面
-->
<template>
  <div>
    <h1>{{ platform }}</h1>
    <h1>{{ userAgent }}</h1>
  </div>
</template>

<script>
import { DOWNLOAD_LINK } from "@/settings"

export default {
  name: "Download",
  data() {
    return {
      platform: null,
      userAgent: null,
    }
  },
  mounted() {
    this.jumpToDownloadPage()
  },
  methods: {
    jumpToDownloadPage() {
      this.platform = navigator.platform
      this.userAgent = navigator.userAgent
      let userAgent = navigator.userAgent
      let isIOS = userAgent.indexOf("iPhone") > -1 || userAgent.indexOf("Mac OS") > -1
      if (isIOS) {
        console.log("苹果系统")
        window.location.href = DOWNLOAD_LINK.IOS
      }
      let isAndroid = userAgent.indexOf("Android") > -1 || userAgent.indexOf("Linux") > -1
      if (isAndroid) {
        console.log("安卓系统")
        window.location.href = DOWNLOAD_LINK.ANDROID
      }
    }
  }
}
</script>

<style scoped>

</style>
